import React from "react";
import { Link } from "gatsby";
import MainImage from "@/components/MainImage/MainImage";
import Layout from "@/components/Layout/Layout";
import Gallery from "@/components/Gallery/Gallery";

const NFTTemplate = ({ pageContext }) => {
  const {
    displayName,
    description,
    title,
    series,
    counter,
    size,
    cover,
    images,
    additionalInfo,
    nft,
    galleryDisplayed,
  } = pageContext || {};

  return (
    <Layout title={displayName}>
      <div className="px-6 md:px-0 font-avantGarde">
        <div className="max-w-[1350px] mx-auto mt-28">
          <MainImage {...cover} />
        </div>
        <div className="max-w-[835px] mx-auto mt-u md:mt-[75px] text-white flex mb-28">
          <div className="w-full">
            <div className="flex justify-between">
              <div>
                <h2 className="text-regular font-bold mb-4">{displayName}</h2>
                <p className="text-light mb-8">{series}</p>
              </div>
              <div>
                <p className="text-regular text-right mb-2">
                  Voyer Intime {counter}
                </p>
                <p className="text-light text-right">{size}</p>
              </div>
            </div>
            <div className="md:w-1/2">
              <p className="text-semiRegular mb-16">{description}</p>
              <div className="flex justify-between">
                <div>
                  <h3 className="font-medium text-semiRegular mb-8">
                    Info & Metadata
                  </h3>
                  <ul>
                    {additionalInfo &&
                      additionalInfo.map((link) => (
                        <li key={link.id} className="mb-2">
                          <Link
                            className="text-semiRegular text-white"
                            to={link.link}
                          >
                            {link.title}
                          </Link>
                        </li>
                      ))}
                  </ul>
                </div>
                <div>
                  <h3 className="font-medium text-semiRegular mb-8">
                    View NFT on
                  </h3>
                  <ul className="text-right">
                    {nft &&
                      nft.map((link) => (
                        <li key={link.id} className="mb-2">
                          <Link
                            className="text-semiRegular text-white"
                            to={link.link}
                          >
                            {link.title}
                          </Link>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {galleryDisplayed && (
          <div className="mb-u">
            <Gallery title={title} images={images} />
          </div>
        )}
      </div>
    </Layout>
  );
};

export default NFTTemplate;
