import React from "react";
import { Link } from "gatsby";
import Image from "@/components/Image/Image";

const Gallery = ({ title, images }) => (
  <div>
    <div className="mx-auto max-w-[835px]">
      <h2 className="text-white font-regular text-regular w-full justify-between before:max-w-[35%] after:max-w-[35%] text-center flex items-center before:block before:h-px before:w-full before:bg-color-grey-100 after:block after:h-px after:w-full after:bg-color-grey-100">
        {title}
      </h2>
      <ul className="grid grid-cols-1 md:grid-cols-2 gap-x-[25px] gap-y-[45px] items-center justify-center justify-items-center mt-u">
        {images &&
          images.map(({ id, image, description }) => (
            <li key={id}>
              <Link to={`/${id}`}>
                <Image src={image} alt={description} className="h-full" />
              </Link>
            </li>
          ))}
      </ul>
    </div>
  </div>
);

export default Gallery;
